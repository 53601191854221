<template>
  <div v-if="!loading">
    <authenticator class="auth"></authenticator>
  </div>
  <div v-else class="mt-6 loading">
    <v-progress-linear indeterminate color="#012b45"></v-progress-linear>
  </div>
</template>

<script>
import { AmplifyEventBus } from "aws-amplify-vue";
import { Hub, Logger } from "aws-amplify";
import { mapState } from "vuex";
import Authenticator from "@/components/Authenticator";
export default {
  data() {
    return {};
  },
  components: {
    Authenticator
  },
  async created() {
    this.authSetup();
  },
  watch: {
    loading() {
      window.scrollTo(0,0)
    }
  },
  computed: {
    ...mapState("user", ["state", "loading"])
  },
  methods: {
    authSetup() {
      const logger = new Logger("My-Logger");
      const listener = async data => {
        switch (data.payload.event) {
          case "signIn":
            await this.$store.dispatch("user/login", data.payload.data);
            this.$router.push("/").catch(err => {});
            break;
          case "signUp":
            // console.log(data);
            // this.$store.dispatch("user/setState", data.payload.data);
            break;
          //   case "signOut":
          //     logger.error("user signed out");
          //     break;
          //   case "signIn_failure":
          //     logger.error("user sign in failed");
          //     break;
          //   case "configured":
          //     logger.error("the Auth module is configured");
        }
      };
      Hub.listen("auth", listener);
    },
    authRoute() {
      AmplifyEventBus.$on("authState", info => {
        this.$store.commit("user/setState", info);
      });
    }
  },
  beforeDestroy() {
    if (this.loading) {
      this.$store.commit("user/setLoading", false)
    }
  }
};
</script>

<style lang="scss">
.loading {
  width: 60%;
  margin: 0 auto;
}
.auth {
  text-align: center;
  @media (max-width: 599px) {
    & > div {
      min-width: 0;
    }
  }
}
:root {
  --amazonOrange: #002b44;
  --lightAmazonOrange: #002b44;
  --darkAmazonOrange: #002b44;
}
.auth {
  .error {
    color: #ff5252 !important;
    background-color: #fff !important;
    font-size: 0.9rem;
  }
}
</style>